import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectInvoiceForm, setInvoiceForm } from 'redux/invoices/createInvoiceWithTemplateSlice';
import styles from './InvoiceFooterSection.module.scss';
import WidgetTextEditor2 from 'components/WidgetTextEditor2/WidgetTextEditor2';

const InvoiceFooterSection = () => {
  const dispatch = useAppDispatch();
  const invoiceForm = useAppSelector(selectInvoiceForm);

  const { footer } = invoiceForm;

  if (!footer) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <WidgetTextEditor2
        height={300}
        value={footer}
        onBlurMethod
        hasContainerBorder
        onChange={(value) =>
          dispatch(
            setInvoiceForm({
              footer: value
            })
          )
        }
      />
    </div>
  );
};

export default InvoiceFooterSection;
