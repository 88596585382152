import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUnclaimedAdSignUp } from 'utils/http/ClinicianProfileService/Profile/profile';
import { security } from 'utils/security';

export const useGetUnclaimedAdSignUp = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const [isFetching, setIsFetching] = useState(false);
  const [isInitialised, setIsInitialised] = useState(false);

  const fetchUnclaimedAdSignUp = useCallback(async () => {
    if (!isInitialised && !isFetching) {
      setIsFetching(true);
      const token = await security.getAccessTokenSilently();

      if (token) {
        try {
          const { claimed, deactivated } = await (await getUnclaimedAdSignUp(token)).json();

          if (deactivated) {
            navigate('/403');
            return;
          }

          if (claimed) {
            window.location.assign('/');
            setTimeout(() => window.location.reload(), 100);
            return;
          }

          setIsInitialised(true);
          setIsFetching(false);
          return;
        } catch (ex) {
          console.error(ex);
        }
      }

      // goto /pending-setup if error or no token
      navigate('/pending-setup');
    }
  }, [isFetching, isInitialised, navigate]);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        fetchUnclaimedAdSignUp();
      } else {
        navigate('/login');
      }
    }
  }, [fetchUnclaimedAdSignUp, isAuthenticated, isLoading, navigate]);

  return {
    isLoading: isLoading || isFetching || !isInitialised
  };
};
