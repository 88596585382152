import { Divider } from 'antd';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import Card from 'components/Card/Card';
import FlexBox from 'components/FlexBox/FlexBox';
import SelectClientList from 'components/SelectClient/SelectClientList/SelectClientList';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { mbsItemLookup } from 'pages/InvoiceTemplate/helpers/helpers';
import { IInvoiceTemplate, PaymentMethod, ItemOverview, RecipientTypes } from 'pages/Invoices/interface';
import { Appointment } from 'pages/InvoiceGenerator/interface';
import { calculateTaxValue, calculateTotalValue } from 'pages/InvoiceGeneratorWithTemplate/helpers';
import { GroupsFromAPI } from 'pages/Groups/Interfaces/Groups';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectSelectedClient,
  selectSelectedGroup,
  selectParticipantType,
  setSelectedClient,
  setSelectedGroup,
  setParticipantType,
  setInvoiceForm,
  selectInvoiceForm,
  setMedicareClaim,
  setMedicareServiceProvider,
  resetClientInvoiceData
} from 'redux/invoices/createInvoiceWithTemplateSlice';
import {
  useGetAppointmentsByGroupIdQuery,
  useGetAppointmentsByRecordIdsQuery
} from 'redux/endpoints/scheduleServices/appointment';
import { filterInvoiceAppointmentTags } from 'utils/filterInvoiceAppointmentTags';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';

import SelectTemplateDropdown from './components/SelectTemplateDropdown/SelectTemplateDropdown';
import IncludeAppointments from './components/IncludeAppointments/IncludeAppointments';
import IncludePaymentMethods from './components/IncludePaymentMethods/IncludePaymentMethods';
import { MOMENTJS_DATE_FORMAT } from 'utils/dateChecker';
import { useGetClinicianProfileByIdQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import { useEffect, useMemo } from 'react';
import { useGetMyMedicareProviderQuery } from 'redux/endpoints/billingServices/clinicianMedicareProvider';
import { getClinicianDefaultProvider } from 'utils/helpers/getClinicianDefaultProvider';
import { TIME_FORMAT } from 'pages/Calendar/components/Modals/FinderWizardModal/constants';
import PayerSelection from './components/PayerSelection/PayerSelection';
import ReferralSelection from './components/ReferralSelection/ReferralSelection';

interface LeftSectionProps {
  clinicianName: string;
  isPaymentMethodsLoading: boolean;
  paymentMethods: PaymentMethod[];
  refetchPaymentMethods: () => void;
  invoiceTemplateList: IInvoiceTemplate[];
  invoiceTemplate: IInvoiceTemplate;
}

const LeftSection = ({
  clinicianName,
  isPaymentMethodsLoading,
  paymentMethods,
  refetchPaymentMethods,
  invoiceTemplateList,
  invoiceTemplate
}: LeftSectionProps) => {
  const { invoiceId = '' } = useParams<{ invoiceId?: string }>();
  const navigate = useNavigate();
  const { INVOICES, FORMS } = useRoutesGenerator();
  const { accountId } = useGetAccountId();

  const dispatch = useAppDispatch();
  const selectedClientRecord = useAppSelector(selectSelectedClient);
  const selectedGroup = useAppSelector(selectSelectedGroup);
  const participationType = useAppSelector(selectParticipantType);
  const invoiceForm = useAppSelector(selectInvoiceForm);

  const selectedAppointments = useMemo(() => invoiceForm?.items || [], [invoiceForm?.items]);
  const selectedClientRecordId = invoiceForm?.clientRecord?._id;
  const selectedGroupId = invoiceForm?.group?._id;
  const isAddAppointmentItemDisabled = !invoiceTemplate.allowMultipleItems && selectedAppointments.length >= 1;

  const {
    data: appointmentClinicianProfile,
    isLoading: isAppointmentClinicianProfileLoading,
    isFetching: isAppointmentClinicianProfileFetching
  } = useGetClinicianProfileByIdQuery(
    { clinicianId: selectedAppointments[0]?.clinicianId || '' },
    { skip: selectedAppointments.length !== 1 || !selectedAppointments[0]?.clinicianId }
  );

  const {
    data: medicareProviders,
    isLoading: isMedicareProvidersLoading,
    isFetching: isMedicareProvidersFetching
  } = useGetMyMedicareProviderQuery({ activeOnly: true });

  const foundMbsItem = useMemo(() => {
    return !isAppointmentClinicianProfileLoading &&
      !isAppointmentClinicianProfileFetching &&
      selectedAppointments.length === 1 &&
      selectedAppointments[0].deliveryType &&
      selectedAppointments[0].startTime &&
      selectedAppointments[0].endTime
      ? mbsItemLookup({
          deliveryType: selectedAppointments[0].deliveryType,
          duration: moment(selectedAppointments[0].endTime, TIME_FORMAT).diff(
            moment(selectedAppointments[0].startTime, TIME_FORMAT),
            'minutes'
          ),
          clinicianRole: appointmentClinicianProfile?.medicare?.role
        })
      : undefined;
  }, [
    appointmentClinicianProfile,
    isAppointmentClinicianProfileFetching,
    isAppointmentClinicianProfileLoading,
    selectedAppointments
  ]);

  const foundProvider = useMemo(() => {
    if (
      !isMedicareProvidersLoading &&
      !isMedicareProvidersFetching &&
      medicareProviders &&
      medicareProviders.length > 0 &&
      selectedAppointments.length === 1 &&
      selectedAppointments[0].clinicianId
    ) {
      return getClinicianDefaultProvider(
        medicareProviders.filter(({ clinicianId }) => clinicianId === selectedAppointments[0].clinicianId)
      );
    }
    return undefined;
  }, [medicareProviders, isMedicareProvidersLoading, isMedicareProvidersFetching, selectedAppointments]);

  useEffect(() => {
    if (!invoiceId) {
      dispatch(
        setMedicareClaim({
          mbsCode: foundMbsItem?.mbsCode || ''
        })
      );
    }
  }, [foundMbsItem, dispatch, invoiceId]);

  useEffect(() => {
    if (!invoiceId) {
      dispatch(
        setMedicareServiceProvider({
          name: (foundProvider && foundProvider.name) || '',
          providerNumber: (foundProvider && foundProvider.providerNumber) || ''
        })
      );
    }
  }, [foundProvider, dispatch, invoiceId]);

  const onSelectClientRecord = (value?: clientRecordsInterface) => {
    value &&
      dispatch(
        setInvoiceForm({
          clientRecord: {
            _id: value._id
          },
          group: undefined,
          items: []
        })
      );
    dispatch(setSelectedClient(value));
    dispatch(resetClientInvoiceData());
  };

  const onSelectGroups = (value?: GroupsFromAPI) => {
    value &&
      dispatch(
        setInvoiceForm({
          group: {
            _id: value._id
          },
          clientRecord: undefined,
          items: []
        })
      );
    dispatch(setSelectedGroup(value));
  };

  const onChangeParticipationType = (value: ParticipantType) => {
    dispatch(setParticipantType(value));
  };

  const onAddAppointment = (appointment: Appointment) => {
    const { itemOverviews } = invoiceTemplate;
    const { items, taxRate } = invoiceForm;
    const { _id, date, rate, startTime, endTime, markedStatus = [], sessionTypeName } = appointment;
    const hasId = itemOverviews.includes(ItemOverview.Id);
    const hasName = itemOverviews.includes(ItemOverview.Name);
    const hasDuration = itemOverviews.includes(ItemOverview.Duration);
    const hasDate = itemOverviews.includes(ItemOverview.Date);
    const hasDelivery = itemOverviews.includes(ItemOverview.Owner);
    const hasStatus = itemOverviews.includes(ItemOverview.Status);

    const duration = moment(endTime, TIME_FORMAT).diff(moment(startTime, TIME_FORMAT), 'minute');
    const appointmentDate = moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    const overview = `${hasName ? sessionTypeName : ''} ${hasName && hasId ? '-' : ''} ${hasId ? _id : ''}`;
    const description = `${hasDuration ? `${duration} minutes` : ''} ${hasDuration && hasDate ? 'on' : ''} ${
      hasDate ? appointmentDate : ''
    } ${hasDelivery ? `by ${clinicianName}` : ''}`;
    const quantity = 1;
    const cost = rate || 0;
    const tags = hasStatus ? filterInvoiceAppointmentTags(markedStatus) : null;

    const item = {
      appointmentId: appointment._id,
      overview: overview.trim(),
      description: description.trim(),
      quantity: quantity.toString(),
      cost: cost.toFixed(2),
      tags: tags,
      appointmentDate: appointmentDate,
      tax: calculateTaxValue(taxRate, cost, quantity).toFixed(2),
      total: calculateTotalValue(taxRate, cost, quantity).toFixed(2),
      clinicianId: appointment.clinicianId,
      deliveryType: appointment.deliveryType,
      startTime: appointment.startTime,
      endTime: appointment.endTime
    };
    dispatch(
      setInvoiceForm({
        items: [...items, item],
        medicare: {
          ...invoiceForm.medicare,
          claim:
            items.length === 0
              ? {
                  ...invoiceForm.medicare?.claim,
                  dateOfService: moment(item.appointmentDate, 'DD-MM-YYYY').format(MOMENTJS_DATE_FORMAT)
                }
              : undefined
        }
      })
    );
  };

  const onRemoveAppointment = (appointment: Appointment) => {
    if (appointment) {
      const { items } = invoiceForm;
      const newItems = items.filter((item) => item.appointmentId !== appointment._id);
      dispatch(
        setInvoiceForm({
          items: newItems,
          medicare: {
            ...invoiceForm.medicare,
            claim: {
              ...invoiceForm.medicare?.claim,
              dateOfService:
                newItems.length === 1 && newItems[0].appointmentDate
                  ? moment(newItems[0].appointmentDate, 'DD-MM-YYYY').format(MOMENTJS_DATE_FORMAT)
                  : ''
            }
          }
        })
      );
    }
  };

  const onAddPaymentMethod = (paymentMethod: PaymentMethod) => {
    const { paymentMethods } = invoiceForm;
    dispatch(setInvoiceForm({ paymentMethods: [...paymentMethods, paymentMethod] }));
  };

  const onRemovePaymentMethod = (paymentMethod: PaymentMethod) => {
    const { paymentMethods } = invoiceForm;
    dispatch(
      setInvoiceForm({
        paymentMethods: paymentMethods.filter((item) => item._id !== paymentMethod._id)
      })
    );
  };

  const {
    data: clientAppointmentsData,
    isLoading: isClientAppointmentsLoading,
    isFetching: isClientAppointmentsFetching
  } = useGetAppointmentsByRecordIdsQuery(
    {
      accountId,
      from: moment('2020-10-01').format('YYYY-MM-DD'),
      to: moment().add(1, 'year').format('YYYY-MM-DD'),
      clientRecordIds: selectedClientRecordId || ''
    },
    {
      skip: !selectedClientRecordId
    }
  );

  const targetClientAppointments =
    clientAppointmentsData &&
    clientAppointmentsData.find((appointment) => appointment._id === selectedClientRecordId)?.totalAppointments;

  const {
    data: groupAppointmentsData,
    isLoading: isGroupAppointmentsLoading,
    isFetching: isGroupAppointmentsFetching
  } = useGetAppointmentsByGroupIdQuery(
    {
      from: moment('2020-10-01').format('YYYY-MM-DD'),
      to: moment().add(60, 'day').format('YYYY-MM-DD'),
      groupId: selectedGroupId
    },
    {
      skip: !selectedGroupId
    }
  );

  const isAppointmentsLoading =
    isClientAppointmentsLoading ||
    isClientAppointmentsFetching ||
    isGroupAppointmentsFetching ||
    isGroupAppointmentsLoading;

  return (
    <FlexBox direction="column" spacing={20}>
      <SelectClientList
        title={'Create Invoice'}
        selectedClientRecord={selectedClientRecord}
        selectedClientRecordId={selectedClientRecordId}
        showModalIfUnselected
        onSelectClientRecord={onSelectClientRecord}
        selectedGroup={selectedGroup}
        onSelectGroups={onSelectGroups}
        participationType={participationType}
        onChangeParticipationType={onChangeParticipationType}
      />
      <Card>
        <SelectTemplateDropdown
          onAddNewTemplate={() => {
            navigate(FORMS.NEW_INVOICE_TEMPLATE_EDITOR);
          }}
          onChangeTemplate={(invoiceTemplateId: string) => {
            dispatch(resetClientInvoiceData());
            navigate(`${INVOICES.BASE}/new`, { state: { invoice: { template: { _id: invoiceTemplateId } } } });
          }}
          templateList={invoiceTemplateList}
          selectedTemplate={invoiceTemplate?.name}
        />
      </Card>
      {selectedClientRecordId && invoiceTemplate.recipient.type === RecipientTypes.Funder && (
        <Card>
          <PayerSelection clientRecordId={selectedClientRecordId} />
        </Card>
      )}
      {selectedClientRecordId && invoiceTemplate.medicareReferral && (
        <Card>
          <ReferralSelection clientRecordId={selectedClientRecordId} />
        </Card>
      )}
      <Card>
        <IncludeAppointments
          isAppointmentsLoading={isAppointmentsLoading}
          appointments={selectedClientRecordId ? targetClientAppointments : groupAppointmentsData}
          selectedAppointmentIds={selectedAppointments.map((item) => item.appointmentId)}
          isClientSelected={!!selectedClientRecordId}
          isGroupSelected={!!selectedGroupId}
          participationType={participationType}
          onAddAppointment={onAddAppointment}
          onRemoveAppointment={onRemoveAppointment}
          isAddAppointmentItemDisabled={isAddAppointmentItemDisabled}
        />
        <Divider style={{ margin: 0 }} />
        <IncludePaymentMethods
          onAddPaymentMethod={onAddPaymentMethod}
          onRemovePaymentMethod={onRemovePaymentMethod}
          paymentMethods={paymentMethods}
          isPaymentMethodsLoading={isPaymentMethodsLoading}
          refetchPaymentMethods={refetchPaymentMethods}
        />
      </Card>
    </FlexBox>
  );
};

export default LeftSection;
